/*-------------- PRICE PLAN ------*/
#price {
    padding: 40px 0;
    background: #fff;
  }
  .single-price {
    margin: 10px auto;
    display: inline;
    float: left;
    width: 100%;
    background-color: #fff;
    transition: 0.5s;
    box-shadow: 0 1px 5px #333;
  }
  .single-price:hover {
    box-shadow: 0 2px 20px #333;
    cursor: pointer;
  }
  .price-head {
    background-color: #412c76;
    display: inline;
    float: left;
    padding: 10px 5px;
    text-align: center;
    width: 100%;
  }
  .price-head h2 {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
    padding: 5px;
    text-transform: uppercase;
  }
  .price-head p {
    font-size: 15px;
    color: #ccc;
    font-weight: bold;
    line-height: 30px;
  }
  .price-head span {
    font-size: 15px;
    color: #ccc;
  }
  .price-content {
    display: inline;
    float: left;
    width: 100%;
    padding: 0 15px;
  }
  .price-content ul li {
    border-bottom: 1px solid #efefef;
    padding: 15px 10px;
    list-style: none;
  }
  ul li:last-child {
    border: none;
  }
  .fa-check-circle {
    color: green;
    margin-right: 10px;
    font-size: 20px;
  }
  .fa-times-circle {
    color: red;
    margin-right: 10px;
    font-size: 20px;
  }
  .price-button {
    display: inline;
    float: left;
    padding: 15px 15px 30px;
    text-align: center;
    width: 100%;
  }
  .buy-btn {
    background-color: #412c76;
    border-radius: 3px;
    display: inline-block;
    font-size: 18px;
    padding: 15px 50px;
    transition: 0.5s;
  }
  .buy-btn:hover {
    border-color: 1px solid #fff;
    background-color: #1d2f96;
  }
  a {
    text-decoration: none !important;
    color: #fff !important;
  }
  #testimonials {
    padding-top: 50px;
    padding-bottom: 50px;
  }
.cardii {
  background: #412c76;
  min-width: 300px;
  height: 140px;
  margin: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
}

.cardii i {
  color: #fff;
  font-size: 2rem;
  position: absolute;
  transition: all 0.4s ease;
}

.card-title {
  color: #fff;
  font-size: 1.2rem;
  text-transform: uppercase;
  transform: translate(0, -80px);
  transition: all 0.4s ease;
}

.card-info {
  color: #fff;
  margin-top: 10px;
  transform: translate(0, 80px);
  transition: all 0.4s ease;
}

.cardii:hover i {
  transform: scale(2);
  opacity: 0;
}

.cardii:hover .card-title {
  transform: translate(0, 0);
}
.cardii:hover .card-info {
  transform: translate(0, 0);
}

/* body {
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 41, 255, 0.02) 0%,
    rgba(0, 41, 255, 0) 100%
  );
} */
label,
input,
textarea {
  display: block;
  width: 100%;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  padding: 0.3em;
}
span {
  font-weight: 700;
  color: #102a43;
  line-height: 35px;
  line-height: 2.5rem;
  font-size: 12px;
  font-size: 0.8rem;
  text-transform: uppercase;
}
input[type="submit"] {
  background: #412c76;
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
  border-radius: 5px;
  margin-top: 1.3em;
}

.form-container {
  /* width: 460px; */
  margin: auto;
}
form {
  background-color: #ffffff;
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  overflow: hidden;
}

.required-star {
  color: #412c76;
}

input,
textarea {
  width: 100%;
  padding: 9px 20px;
  border: 1px solid #e1e2eb;
  background-color: #fff;
  color: #102a43;
  caret-color: #829ab1;
  box-sizing: border-box;
  font-size: 14px;
  font-size: 1rem;
  line-height: 29px;
  line-height: 2rem;
  box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.2);
  border-radius: 3px;
  line-height: 29px;
  line-height: 2rem;
}

@media screen and (max-width: 940px) {
  .jumbotron-container img {
    width: 5rem;
    height: 3rem;
  }
  /* .jumbotron-container{
        margin: 1rem ;
    } */
}

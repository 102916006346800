.section-head {
  margin-bottom: 20px;
}
.section-head h4 {
  position: relative;
  padding: 0;
  color: #412c76;
  line-height: 1;
  letter-spacing: 0.3px;
  font-size: 34px;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  margin-bottom: 30px;
}
.section-head h4:before {
  content: "";
  width: 60px;
  height: 3px;
  background: #412c76;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
}
.section-head h4 span {
  font-weight: 700;
  padding-bottom: 5px;
  color: #2f2f2f;
}
p.service_text {
  color: #cccccc !important;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}
.section-head p,
p.awesome_line {
  color: #818181;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  font-weight: 300;
}

.extra-text {
  font-size: 34px;
  font-weight: 700;
  color: #2f2f2f;
  margin-bottom: 25px;
  position: relative;
  text-transform: none;
}
.extra-text::before {
  content: "";
  width: 60px;
  height: 3px;
  background: #412c76;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
}
.extra-text span {
  font-weight: 700;
  color: #412c76;
}
.item {
  background: #fff;
  cursor: pointer;
  text-align: center;
  padding: 30px 25px;
  -webkit-box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  border: 5px solid rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}
.item:hover {
  background: #412c76;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}
.item:hover .item,
.item:hover span.icon {
  background: #fff;
  border-radius: 10px;
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}
.item:hover h6,
.item:hover p,
.item:hover p span {
  color: #fff;
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}
.item .icon {
  font-size: 20px;
  margin-bottom: 25px;
  color: #412c76;
  width: 90px;
  height: 90px;
  line-height: 96px;
  border-radius: 50px;
}
.item .feature_box_col_one {
  background: rgba(247, 198, 5, 0.2);
  color: #412c76;
  padding: 1.6rem 1.8rem;
}
.item .feature_box_col_two {
  background: rgba(255, 77, 28, 0.15);
  color: #412c76;
  padding: 1.6rem 1.7rem;
}
.item .feature_box_col_three {
  background: rgba(0, 147, 38, 0.15);
  color: #412c76;
  padding: 1.5rem 1.8rem;
}

.item p {
  font-size: 15px;
  line-height: 26px;
  text-transform: capitalize;
}
.item p span{
  font-size: 15px;
  line-height: 26px;
  text-transform: lowercase;
}
.item h6 {
  margin-bottom: 20px;
  margin-top: 10px;
  color: #2f2f2f;
  font-weight: 700;
}
.mission p {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;
}
.mission i {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 50px;
  text-align: center;
  background: #412c76;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
}
.mission .small-text {
  margin-left: 10px;
  font-size: 13px;
  color: #666;
}
.skills {
  padding-top: 0px;
}
.skills .prog-item {
  margin-bottom: 25px;
}
.skills .prog-item:last-child {
  margin-bottom: 0;
}
.skills .prog-item p {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
}
.skills .prog-item .skills-progress {
  width: 100%;
  height: 10px;
  background: #e0e0e0;
  border-radius: 20px;
  position: relative;
}
.skills .prog-item .skills-progress span {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #412c76;
  width: 10%;
  border-radius: 10px;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.skills .prog-item .skills-progress span:after {
  content: attr(data-value);
  position: absolute;
  top: -5px;
  right: 0;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  padding: 3px 7px;
  border-radius: 30px;
}

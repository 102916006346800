#about {
    padding-bottom: 50px;
    color: #555;
  }
 
  .about-content {
    padding-top: 20px;
  }
  .skills-bar p {
    margin-bottom: 6px;
    font-weight: 600;
  }
  .progress-bar {
    border-radius: 16px;
    background-color: #412c76 !important;
    width: 100%;
  }
  .progress {
    border-radius: 16px !important;
    margin-bottom: 20px;
  }
  .bolder{
     color: #412c76; 
  }

@media screen and (max-width: 940px) {
    .skills-bar{
        margin-top: 1rem;
    }
  }
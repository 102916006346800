.banner3-container{
    background-color: #412c76;
    background-image: linear-gradient(
        rgba(65, 44, 118, 0.8),
        rgba(65, 44, 118, 0.8)
      ),
      url("../../../assets/images/services.jpg");
    background-position: center;
    background-size: cover;
    color: #ffffff;
}
.header {
  position: fixed;
  height: 70px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.2s ease-in-out;
  overflow: hidden;
  background-color: transparent;
}
.header-bg {
  background-color: rgb(65, 44, 118);
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
  margin: auto;
  height: 100%;
  padding: 0 2rem;
}

.header .nav-menu a {
  color: #ffffff;
}

.header .navbar img {
  height: 45px;
  border-radius: 3px;

}

.header .nav-menu {
  display: flex;
  align-items: center;
}

.header .nav-item {
  padding: 1rem;
  font-weight: 500;
}

.header .nav-item a:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--secondary-color);
}

.hamburger {
  display: none;
}

ul li {
  list-style: none;
  text-decoration: none;
}
.link {
  text-decoration: none;
}
.nav-item p {
  color: #fff;
  font-weight: 700;
}
.nav-button {
  color: #fff;
  background-color: #412c76;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  border: none;
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  transition: 0.3s ease-in-out;
}
.nav-button:hover {
  color: #412c76;
  background-color: #fff;
}
.nav-item-2 p{
  color: #fff;
  font-weight: 700;
  
}
.nav-item2{
  display: none;
}
@media screen and (max-width: 940px) {
  .header {
    max-width: 100%;
    background-color: transparent;
  }
  .header-bg {
    background-color: rgb(65, 44, 118);
  }
  .header .navbar {
    max-width: 100%;
    padding: 0 2rem; 
    align-items: center;

  }

  .hamburger {
    display: block;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 50px;
    flex-direction: column;
    background-color: rgb(65, 44, 118);
    width: 100%;
    height: 100vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: .7rem 0;
  }

  .header .navbar img {
    width: 70px;
    margin-top: 10px;
  }
 
  .nav-item2{
    display: flex;
  }
  .nav-button2 {
    color: #412c76;
    background-color: #fff;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    border: none;
    border-top-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    transition: 0.3s ease-in-out;
  }
  .nav-item p {
    color: #fff;
  }
}

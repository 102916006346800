.team-container h1{
    font-weight: 700;
}

.team-container p{
    font-weight: 700;
    color: #868686;
}

.team p{
    display: none;
}

/* style member component */
.list-members {
    background: #fff;
    width: 80%;
    margin: 4% auto;
    display: flex;
    flex-wrap: wrap;
  }
  
  .member {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .member-image {
    width: 50%;
    height: 100%;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  
  .member-image img {
    width: 100%;
    height: 100%;
    transition: 1s;
  }
  
  .member-image:hover img {
    transform: scale(1.1);
  }
  
  .member-info {
    width: 50%;
    text-align: center;
  }
  
  .member-info p {
    margin: 20px 0;
  }
  
  
  /* style social link */
  .social-link .fab {
    width: 35px;
    height: 35px;
    line-height: 35px;
    border: 1px solid #412c76;
    margin: 0 7px;
    cursor: pointer;
    transition: transform .5s;
    color: #412c76;
  }
  
  .social-link .fab:hover {
    background: #412c76;
    color: #fff;
    transform: translateY(-7px);
  }
  
  /* Membuat segitiga */
  .member-image::after {
    content: '';
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 15px solid #fff;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  
  .fa-arrow-right{
      margin-left: 15px;
      color: #412c76;
  }
  .view-all p{
      color: #412c76;
  }
  /* Merubah posisi member-image dengan member-info */
  @media screen and (min-width: 771px) {
    .member:nth-child(4n+3) .member-info,
    .member:nth-child(4n+4) .member-info {
      order: 1;
    }
    .member:nth-child(4n+3) .member-image,
    .member:nth-child(4n+4) .member-image {
      order: 2;
    }
  
    /* Merubah posisi sigitiga pada baris genap */
    .member:nth-child(4n+3) .member-image::after,
    .member:nth-child(4n+4) .member-image::after {
      left: 0;
      right: auto;
      transform: translateY(-50%) rotateZ(180deg);
    }
  }
  
  

@media screen and (max-width: 940px) {
    .team-container h1{
        font-weight: 700;
        font-size: 1.4rem;
    }
    
    .team-container p{
        display: none;
    }
    .team p{
        display: inline;
    }
    .list-members {
        width: 95%;
      }
      .member {
        flex-basis: 100%;
        font-size: 14px;
      }
      .social-link .fab {
        width: 30px;
        height: 30px;
        line-height: 30px;
      }
    
      .member:nth-child(even) .member-info {
        order: 1;
      }
      .member:nth-child(even) .member-image {
        order: 2;
      }
      
      /* Merubah posisi sigitiga elemen genap */
      .member:nth-child(even) .member-image::after {
        left: 0;
        right: auto;
        transform: translateY(-50%) rotateZ(180deg);
      }
}
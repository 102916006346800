.banner-container2{
    background-color: #412c76;
    background-image: linear-gradient(
        rgba(65, 44, 118, 0.8),
        rgba(65, 44, 118, 0.8)
      ),
      url("../../../../assets/images/team.jpg");
    background-position: center;
    background-size: cover;
    color: #ffffff;
}
.banner-content{
    padding: 1em;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 4rem;
    align-items: center;
}
.banner-content h1{
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
}


@media screen and (max-width: 940px) {
    .banner-content h1{
        font-size: 1.5rem;
        text-align: center;
    }
    .banner-content p{
        text-align: center;
    }
    .banner-content{
        height: 50vh;
        
    }
  }
  

.shadoww{
    border-radius: 1rem;
    border: 1px solid #ccc;
}
.shadoww img{
    height: 7rem;
    width: 5rem;
    padding-top: 2rem;
}
.shadoww p{
    font-size: 1.3rem;
    padding-top: 1rem;
}
.icon img{
    height: 2rem;
    width: 2rem;
}
.download img{
    height: 5rem;
    width: 3rem;
}
@media screen and (max-width: 940px) {
  .pdf {
    margin-bottom: 2rem;
  }
}

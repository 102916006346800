.landing-section {
  background-color: #412c76;
  /* background-image: linear-gradient(
      rgba(65, 44, 118, 0.8),
      rgba(65, 44, 118, 0.8)
    ),
    url("../../../../assets/images/students.jpg");
  background-position: center; */
  background-size: cover;
  color: #ffffff;
}

.landing-container {
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  /* padding: 0 4rem; */
}

.landing-container .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.texts {
  width: 70%;
}

.landing-container p {
  font-weight: 700;
  color: rgb(218, 218, 218);
  text-transform: capitalize;
  font-size: 1.5rem;
  margin-top: 2rem;
}
.landing-container h1 {
  font-weight: 700;
  text-transform: capitalize;
  color: rgb(255, 255, 255);
  font-size: 4.5rem;
}
.landing-text h1 span {
  font-weight: 700;
  text-transform: capitalize;
  color: rgb(65, 44, 118);
  font-size: 3rem;
}
.landing-text {
  margin-top: 4rem;
}
.buttons {
  margin-top: 2rem;
}
.button {
  color: #fff;
  background-color: rgb(65, 44, 118);
  width: 10rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  border: none;
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  transition: 0.5s ease-in-out;
}
.button:hover {
  color: rgb(65, 44, 118);
  background-color: rgb(255, 255, 255);
  /* border: .5px solid #412c76; */
}
.button2 {
  color: rgb(65, 44, 118);
  border: 1px solid #412c76;
  width: 10rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  background-color: #fff;
  margin-left: 1rem;
  transition: 0.5s ease-in-out;
}
.button2:hover {
  color: #fff;
  background-color: rgb(65, 44, 118);
  border: 1px solid #412c76;
}
.count-ups p {
  font-weight: 700;
  font-size: 1.5rem;
}
.count-ups p span {
  font-size: 1rem;
}
.design1 {
  background-color: rgba(212, 165, 8, 0.733);
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  border-bottom-right-radius: 2rem;
}
.design2 {
  background-color: rgba(62, 8, 212, 0.356);
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  border-bottom-right-radius: 2rem;
}
.design3 {
  background-color: rgba(212, 8, 35, 0.384);
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  border-bottom-right-radius: 2rem;
}

/* media queries */

@media screen and (max-width: 940px) {
  .landing-container {
    width: 100%;

    color: #ffffff;
    height: 47vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .landing-container img {
    height: 100%;
    width: 100%;
    background-size: contain;
  }
  .landing-container .content {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .landing-container h1 {
    font-weight: 700;
    text-transform: capitalize;
    color: rgb(255, 255, 255);
    font-size: 1.8rem;
  }
  .landing-container p {
    font-weight: 700;
    color: rgb(218, 218, 218);
    text-transform: capitalize;
    font-size: 1rem;
    margin-top: 2rem;
  }
  .texts {
    width: 100%;
  }
}
.carousel-control-next,
.carousel-control-prev {
  z-index: 0 !important;
}

.carousel-indicators {
  z-index: 0 !important;
}
.carousel-control-next, .carousel-control-prev {
    z-index: 0 !important;
}

.carousel-indicators{
  z-index: 0 !important;
}
.jumbotron-container{
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 100px;
    /* box-shadow: 0px 0px 5px #ccc; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    
}
.jumbotron-container img{
    /* width: 100%; */
    height: 100px;
}




@media screen and (max-width: 940px) {
    .jumbotron-container img{
        width: 5rem;
        height: 3rem;
    }
    /* .jumbotron-container{
        margin: 1rem ;
    } */
}